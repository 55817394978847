<template>
  <div id="fee-management" class="mb-5">
    <div class="columns is-multiline">
      <div
        class="column is-8 is-offset-2 card mb-3"
        v-for="item in items"
        :key="item.name"
      >
        <router-link :to="item.route">{{ item.name }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageTitle: 'Reports',
      schoolId: null,
    }
  },
  computed: {
    items() {
      return [
        {
          name: 'School Fees Report',
          route: `/school/${this.schoolId}/school_fees_report`,
        },
        {
          name: 'School Fees - Class Group Report',
          route: `/school/${this.schoolId}/school_fees_class_group_report`,
        },
        {
          name: 'School Fees Debts',
          route: `/school/${this.schoolId}/school_fees`,
        },
        {
          name: 'Generate Receipts',
          route: '/fee_items',
        },
      ]
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Fee Management',
        route: `/school/${this.schoolId}/fee_management`,
      },
    ])
  },
}
</script>
